<template>
    <div>

        <b-alert
            variant="info"
            class="mb-0"
            show
        >
            <div class="alert-body mb-1">
                <icon icon="info-circle" class="mr-50" />
                <span class="my-auto">{{capitalize($t('since', {'since': 'le 1 juillet 2022'}))}}</span>
            </div>
        </b-alert>

        <div class="d-flex justify-content-between mb-2">
            <h1>{{ capitalize($t('balance:')) }}</h1>
            <h1 v-if="user._ledger"
                :class="user._ledger.balance==0?'text-primary':'text-danger'"
            >
                <span v-if="user._ledger.balance>0">+</span>
                {{ currency(user._ledger.balance) }}
            </h1>
        </div>

        <b-table striped
                 borderless
                 hover
                 foot-clone
                 :fields="fields"
                 :items="ledger">

            <template #cell(date)="data">
                <div class="text-nowrap">{{ data.item.date|moment('LL') }}</div>
            </template>

            <template #cell(type)="data">
                <div class="text-nowrap">{{ capitalize($tc(data.item.type)) }}</div>
            </template>

            <template #cell(debit)="data">
                <div
                        v-if="data.item.debit != 0"
                        class="text-nowrap"
                >
                    {{ currency(data.item.debit) }}
                </div>
                <div v-else></div>
            </template>

            <template #cell(credit)="data">
                <div
                        v-if="data.item.credit != 0"
                        class="text-nowrap"
                >
                    {{ currency(data.item.credit) }}
                </div>
                <div v-else></div>
            </template>

            <template #foot()="data">
                <div v-if="data.column == 'debit' && user._ledger"
                     class="text-nowrap">
                    {{ currency(user._ledger.totalDebit) }}
                </div>
                <div v-else-if="data.column == 'credit' && user._ledger"
                     class="text-nowrap">
                    {{ currency(user._ledger.totalCredit) }}
                </div>
                <div v-else>

                </div>
            </template>
        </b-table>

    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'
import { currency } from '../../utils/filter'

import i18n from '../../libs/i18n'

export default {
  components: {},
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const fields = computed(() => {
      return [
        {
          key: 'piece',
          label: capitalize(i18n.tc('piece'))
        },
        {
          key: 'date',
          label: capitalize(i18n.tc('date'))
        },
        {
          key: 'type',
          label: capitalize(i18n.tc('type'))
        },
        {
          key: 'wording',
          label: capitalize(i18n.tc('wording'))
        },
        {
          key: 'debit',
          label: capitalize(i18n.tc('debit'))
        },
        {
          key: 'credit',
          label: capitalize(i18n.tc('credit'))
        },
      ]
    })

    const ledger = computed(() => {
      return props.user._ledger ? props.user._ledger.ledger : []
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data

      // Computed
      fields,
      ledger,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>