<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('user',2)"
        :items="users2"
        :items-context="usersContext"
        :available-filters="$store.getters['user2/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="$can('manage', 'contactUsers')"
        :is-view-windows-ready="displayedUser!=null"

        @addItem="userModalShow=true"
        @showDetails="showDetails"
    >
      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editUserLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteUserAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>

      <template #leftViewWindow="{item}">
        <individual-card
            :user="item"
            :is-viewable="true"
            :is-selected="item.id==currentUser.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedUser._display }}
      </template>
      <template #viewWindowButtons>
        <!--          Edit -->
        <button-edit
            @click.native.stop="editUserLocal(currentUser)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <user-view :user="displayedUser"/>
      </template>

    </base-template>

    <!-- modal workFlo -->
    <modal-user
        :user.sync="currentUser"
        :title="('id' in currentUser)?$t('EditUser'):$t('NewUser')"
        :isOpen.sync="userModalShow"
        @submitValidated="submitValidatedUserLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import store from '../../store'

import BaseTemplate from '../base3/Base.vue'
import ButtonEdit from '../button/Edit.vue'
import ButtonRemove from '../button/Remove.vue'
import IndividualCard from '../card/IndividualCard.vue'
import UserView from './UserView.vue'
import ModalUser from '../prompt/User.vue'
import { useUsers } from '../../views/contacts2/contactUsers/useUsers'

export default {
  components: {
    ModalUser,
    UserView,
    IndividualCard,
    ButtonEdit,
    ButtonRemove,
    BaseTemplate
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('contactUsers')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedUser = ref(null)
    const currentUser = ref(null)
    const userModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { users2, usersContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchUsers2(val)
          .then(() => {
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
          })
    }, { deep: true })

    watch(userModalShow, val => {
      if (val == false) {
        resetUser()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchUsers2,
      fetchAutocompleteCompanies
    } = useAPI()

    const { submitValidatedUser, removeUser } = useUsers()

    const loadInit = () => {
      fetchAutocompleteCompanies()

      isAutocompleteLoaded.value = true
    }

    const showDetails = (user) => {
      if (user != null) {
        store.dispatch('user2/getUser', user.id)
            .then(response => {
              displayedUser.value = response.data
            })

      } else {
        resetUser()
      }
    }

    const resetUser = () => {
      currentUser.value = JSON.parse(JSON.stringify(store.getters['user2/getEmptyUser']))
    }

    const editUserLocal = (user) => {
      store.dispatch('user2/getUser', user.id)
          .then(response => {
            currentUser.value = response.data
            userModalShow.value = true
          })
    }

    const removeUserLocal = (user) => {
      removeUser(user)
          .then(response => {

          })
    }

    const submitValidatedUserLocal = () => {
      submitValidatedUser(currentUser.value)
          .then(response => {
            resetUser()
            userModalShow.value = false
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetUser()
    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            listGlobalAmount: currentView.listGlobalAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      module,
      payload,
      isReady,

      displayedUser,
      currentUser,
      userModalShow,

      // Computed
      users2,
      usersContext,

      // Methods
      showDetails,
      editUserLocal,
      removeUserLocal,
      submitValidatedUserLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteUserAlert (user) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theUser') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeUserLocal(user)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>