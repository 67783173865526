<template>
    <b-card
            no-body
            class="overflow-hidden cursor-pointer p-1"
            :class="{'bg-primary bg-lighten-5': (isCardHovered||isSelected)}"

            @mouseover="isCardHovered=true"
            @mouseleave="isCardHovered=false"
            @click="$emit('click')"
    >


        <div class="d-flex justify-content-between ">
            <div class="text-nowrap text-truncate my-auto w-100 mr-1">
                <b-avatar
                        v-if="user.avatar"
                        class="pull-up"
                        :src="user.avatar.url"
                        variant="light-primary"
                        size="sm"
                />
                <b-avatar
                        v-else
                        class="pull-up"
                        :text="getInitials(user.name)"
                        variant="light-primary"
                        size="sm"
                />

                <span class="ml-1">
                    <b>{{ user.name }}</b>
                </span>
            </div>

            <!--      <div class="text-nowrap text-secondary my-auto">-->
            <!--        <small>-->
            <!--          <i>{{ user.siren }}</i>-->
            <!--        </small>-->
            <!--      </div>-->


            <Transition name="slide">
                <div v-if="isCardHovered"
                     class="text-nowrap my-auto ml-2">
                    <icon
                            icon="eye"
                            class="mr-1 cursor-pointer"
                            :class="{'text-primary': isViewHovered}"

                            @mouseover.native="isViewHovered=true"
                            @mouseleave.native="isViewHovered=false"
                            @click.native="$emit('viewClick')"
                    />

                    <icon
                            v-if="isEditAllowed"
                            icon="edit"
                            class="mr-1 cursor-pointer"
                            :class="{'text-warning': isEditHovered}"
                            :disabled="isEditDisabled"

                            @mouseover.native="isEditHovered=true"
                            @mouseleave.native="isEditHovered=false"
                            @click.native="$emit('editClick')"
                    />

                    <icon
                            v-if="isTrashAllowed"
                            icon="trash-alt"
                            class="mr-1 cursor-pointer"
                            :class="{'text-danger': isTrashHovered}"
                            :disabled="isTrashDisabled"

                            @mouseover.native="isTrashHovered=true"
                            @mouseleave.native="isTrashHovered=false"
                            @click.native="$emit('trashClick')"
                    />
                </div>
            </Transition>

        </div>

        <div class="text-truncate">
            <a v-if="user.email"
               :href="'mailto:'+user.email">{{ user.email }}
            </a>
            <span v-else>&nbsp;</span>
        </div>
        <div class="text-truncate">
            <a v-if="user.phone"
               :href="'tel:'+user.phone">{{ user.phone }}
            </a>
            <span v-else>&nbsp;</span>
        </div>

    </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

export default {
  components: {},
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    isEditAllowed: {
      type: Boolean,
      default: true
    },
    isEditDisabled: {
      type: Boolean,
      default: false
    },
    isTrashAllowed: {
      type: Boolean,
      default: true
    },
    isTrashDisabled: {
      type: Boolean,
      default: false
    },
    isViewable: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isCardHovered = ref(false)
    const isViewHovered = ref(false)
    const isEditHovered = ref(false)
    const isTrashHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const getInitials = (display) => {
      let names = display.split(' '),
        initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isCardHovered,
      isViewHovered,
      isEditHovered,
      isTrashHovered,

      // Computed

      // Methods
      getInitials,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: .3s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(200%);
}

.userTextArea {
  p {
    margin-bottom: 0px;
  }
}
</style>